/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'

import { NewsletterSection } from '@components/NewsletterSection'
import { Layout } from '@components/Layout'
import { Box, Flex } from '@components/Grid'
import { SEO } from '@components/SEO'
import { Text } from '@components/Text'
import { Hero } from '@components/Hero'
import { H2, H3, H4, Topline } from '@components/Heading'
import { Blob } from '@components/Blob'
import { Button } from '@components/Button'

const PeoplePage = ({ data: { content }, location }) => {
  return (
    <Layout>
      <SEO
        metadata={{
          title: content.seo.seoTitle,
          description: content.seo.seoDescription,
          pathname: location.pathname,
        }}
      />
      <Hero.Background gradient="linear-gradient(0, hsl(216, 50%, 89%), hsl(214, 100%, 97%))">
        <Hero.Shape shape="default" color="white" />
        <Hero>
          <Hero.Topline>{content._rawHero.topline}</Hero.Topline>
          <Hero.Heading content={content._rawHero.heading} />
          <Hero.Text content={content._rawHero.text} pb={[4, 7, 8]} />
        </Hero>
      </Hero.Background>
      <Flex
        as="section"
        mt="-2px"
        width="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        px={5}
        py={[7, 8]}
      >
        <H3
          textAlign="center"
          color="blue.700"
          content={content._rawFirstSectionClaim}
        />
      </Flex>

      <Flex
        as="section"
        flexDirection="column"
        alignItems="center"
        bg="white"
        px={[5, 6, 7]}
        py={[7, 8, 9]}
      >
        <Topline textAlign="center">{content.firstSectionTopline}</Topline>
        <H2
          mb={[6, 3]}
          textAlign="center"
          content={content._rawFirstSectionHeading}
        />
        <Box
          width="100%"
          maxWidth="1100px"
          display="grid"
          my={[4]}
          gridTemplateColumns={['100%', null, '1fr 1fr', '3fr 2fr']}
          gridTemplateAreas={["'blobs' 'content'", null, "'blobs content'"]}
        >
          <Box display="grid" gridArea="blobs" pr={[0, 0, 0, 8]}>
            <Flex
              justifySelf={['center', null, 'right']}
              alignSelf="center"
              mb={[5, null, 0]}
              mr={[0, null, 5]}
              width={['50%', '40%', '70%']}
              pb={['50%', '40%', '70%']}
              height={['0']}
            >
              <Blob
                fluid={content.firstSectionImage.asset.fluid}
                display="block"
                width="100%"
                pb="100%"
                borderRadius="55% 45% 51% 49% / 49% 35% 65% 51%"
              />
              <Box
                position="absolute"
                bg="#00f"
                width="60%"
                height="60%"
                top="70%"
                left="0"
                borderRadius="50% 50% 37% 63% / 59% 44% 56% 41%"
                css={{
                  transform: 'translate3d(-50%, -50%, 0)',
                  background:
                    'linear-gradient( 210deg, hsla(216,50%,89%, 0.7), hsla(218,37%,80%, 1.0) )',
                }}
              />
            </Flex>
          </Box>
          <Box display="grid" gridArea="content">
            <Flex
              display="block"
              pl={[0, null, 5]}
              px={[0, 5, 0]}
              pt={[5]}
              justifySelf={['center', null, 'start']}
              alignSelf="center"
            >
              <Text content={content._rawFirstSectionText} />
            </Flex>
          </Box>
        </Box>
      </Flex>

      <Box
        as="section"
        bg="sectionBackground.light"
        mt="-2px"
        width="100%"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        px={[5, 6, 7]}
        py={[7, 8]}
      >
        <Flex justifyContent="center">
          <H4 textAlign="center" color="blue.600" mb={5}>
            {content.secondSectionTopline}
          </H4>
        </Flex>
        <Box maxWidth="500px" mx="auto">
          <Text content={content._rawSecondSectionList} />
          <Flex mt={5} justifyContent="center">
            <Button as="a" href={`${content.secondSectionCV.asset.url}?dl=`}>
              {content.secondSectionCTA.label}
              {content.secondSectionCTA.showArrow && <Button.Arrow />}
            </Button>
          </Flex>
        </Box>
      </Box>
      <NewsletterSection />
    </Layout>
  )
}

export const query = graphql`
  query PeoplePage {
    content: sanityPeoplePageSingleton {
      seo {
        seoDescription
        seoTitle
      }
      _rawHero
      _rawFirstSectionClaim
      firstSectionTopline
      _rawFirstSectionHeading
      _rawFirstSectionText
      _rawSecondSectionList
      firstSectionImage {
        asset {
          fluid(maxWidth: 500) {
            ...GatsbySanityImageFluid
          }
        }
      }
      secondSectionTopline
      secondSectionList {
        _key
      }
      secondSectionCTA {
        label
        showArrow
      }
      secondSectionCV {
        asset {
          url
        }
      }
    }
  }
`

export default PeoplePage
